<template>
  <section>
    <BaseTable
      v-loading="loading"
      :data="productCategoryList"
      empty-text="暫無數據"
    >
      <EmptyBlock slot="empty" />
      <BaseElTableColumn prop="name" label="名稱" align="center" />
      <BaseElTableColumn prop="order" label="排序" align="center" />
      <BaseElTableColumn label="操作" fixed="right" width="110" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            @edit="openDialog('update'),(selectRow = scope.row)"
            @delete=";(deleteDialog = true), (selectRow = scope.row)"
          />
        </template>
      </BaseElTableColumn>
    </BaseTable>

    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="productCategoryCount"
      @pageChange="refresh"
    />

    <SmallCategoryCreateDialog
      v-if="showDialog"
      :dialogTitle="dialogTitle"
      :dialogType="dialogType"
      :selectRow="selectRow"
      @refresh="refresh"
      @close="$emit('close'), showDialog=false, selectRow=null"
    />

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="deleteDialog = false"
      @delete="deleteServiceCategory(), (deleteDialog = false)"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import EmptyBlock from '@/components/EmptyBlock.vue'
import SmallCategoryCreateDialog from './SmallCategoryCreateDialog.vue'
import {
  GetProductCategory,
  GetProductCategoryCount,
  FindProductCategory,
  DeleteProductCategory,
} from '@/api/ecommerce/category'
import DeleteDialog from '@/components/Dialog/DeleteDialog'

// Utils
import { pageStartIndex } from '@/utils/table'
import { dialogTitle } from '@/utils/dialog'
import { extractList, imgSrc } from '@/utils/helper'

// import * as cssvars from '@/styles/ohbot/_variables.scss'

export default {
  name: 'ServiceCategorySetting',
  components: { DeleteDialog, EmptyBlock, SmallCategoryCreateDialog },
  props: ['openCreateDialog', 'categoryCount', 'nameSearch'],
  data: () => ({
    tab: 'small',
    loading: false,
    // nameSearch: '',
    uploadDialog: false,
    showDialog: false,
    deleteDialog: false,
    dialogType: '',

    productCategoryList: [],
    productCategoryCount: 0,

    selectRow: null,
    tableOptions: {
      page: 1,
      pageLimit: 10,
    },

    imgSize: 90,
  }),
  computed: {
    ...mapGetters(['shop']),
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },
    dialogTitle () {
      return dialogTitle(this.dialogType, {
        create: '新增商品類別',
        update: '編輯商品類別',
      })
    },
    productsList () {
      if (this.formData.services.length === 0) return []
      return extractList('id', this.formData.services)
    },
  },
  watch: {
    openCreateDialog (open) {
      if (open) this.openDialog('create')
    },
    nameSearch () {
      this.refresh()
    },
  },

  async mounted () {
    await this.refresh()
  },

  methods: {
    tagType (val) {
      let type = 'info'
      if (val) type = 'success'
      if (!val) type = 'danger'
      return type
    },

    imgSource (image) {
      return imgSrc(this.imgSize, image)
    },

    async refresh () {
      this.loading = true
      await this.getServiceCategory()
      await this.getServiceCategoryCount()
      this.loading = false
    },

    //= > 取得產品類別
    async getServiceCategory () {
      this.loading = true
      const startIndex = this.pageStartIndex
      const limit = this.tableOptions.pageLimit
      const [res, error] = await GetProductCategory({
        shopId: this.shop,
        start: startIndex,
        limit,
        name: this.nameSearch === '' ? undefined : this.nameSearch,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      this.productCategoryList = res
    },

    async findServiceCategory () {
      const [res, error] = await FindProductCategory({
        shopId: this.shop,
        id: this.selectRow.id,
      })
      if (error) return this.$message.error(error)
      // this.formData.services = res.AppointmentServices.filter(item => ! item.isRemove)
    },

    //= > 刪除產品類別
    async deleteServiceCategory () {
      this.loading = true
      const [res, error] = await DeleteProductCategory({
        shopId: this.shop,
        id: this.selectRow.id,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      console.log(res)
      await this.refresh()
      this.$message.success('刪除成功')
    },

    //= > 取得服務人員總數
    async getServiceCategoryCount () {
      this.loading = true
      const [res, error] = await GetProductCategoryCount({
        shopId: this.shop,
        name: this.nameSearch === '' ? undefined : this.nameSearch,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      this.productCategoryCount = res.count
      this.$emit('update:categoryCount', res.count)
    },

    async openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },
  },
}
</script>

<style scoped lang="postcss">
.tab-btn {
  @apply pb-[10px] cursor-pointer;
}
.tab-activated {
  @apply border-b-2 border-[red];
}
</style>
